import {useEffect, useState} from 'react';
import {ReactComponent as SoundOn} from '../../../assets/icons/sound-on.svg';
import {ReactComponent as SoundOff} from '../../../assets/icons/sound-off.svg';

const VolumeBtn = () => {
    const [volumStatus, setVolumStatus] = useState(false);

    const handleVisibilitychange = () => {
        if (!volumStatus) return;
        const audio: HTMLAudioElement | null = document.querySelector('#audio');

        document.visibilityState === 'hidden' && audio?.pause();
        document.visibilityState === 'visible' && audio?.play();
    };

    useEffect(() => {
        window.addEventListener('visibilitychange', handleVisibilitychange);

        return () => {
            window.removeEventListener(
                'visibilitychange',
                handleVisibilitychange
            );
        };
    }, [volumStatus]);

    return (
        <>
            <audio id="audio" loop>
                <source src="/leading/leadingPageAssets/music/theplaces.mp3" />
            </audio>
            <button
                className="fixedButtonGroup-leftBottom"
                onClick={() => {
                    const audio: any = document.querySelector('#audio');
                    // const volumeIcon: any =
                    //     document.getElementsByClassName('volume-icon-img')[0];
                    if (!audio) return;

                    if (audio.paused) {
                        audio.play();
                        setVolumStatus(true);
                    } else {
                        setVolumStatus(false);
                        audio.pause();
                    }

                    // audio.paused
                    //     ? (volumeIcon.src =
                    //           '/leading/leadingPageAssets/icons/music_pause.png')
                    //     : (volumeIcon.src =
                    //           '/leading/leadingPageAssets/icons/music.gif');
                }}>
                <div className="volume-icon">
                    {volumStatus ? <SoundOn /> : <SoundOff />}

                    {/* <img
                        className="volume-icon-img"
                        src="/leading/leadingPageAssets/icons/music.gif"
                        alt="Volume button"
                    /> */}
                </div>
            </button>
        </>
    );
};

export default VolumeBtn;
