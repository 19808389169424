import {useRef, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import gsap from 'gsap/all';
// import { gsap } from 'gsap/dist/gsap-core';
import {useTranslation} from 'react-i18next';
import {useDetectGPU} from '@react-three/drei';

const SceneOneBtn = ({
    text,
    click,
    onHover
}: {
    text: string;
    click: () => void;
    onHover: (isHovered: boolean) => void;
}) => {
    const textRef = useRef<any>(null);
    const {t, i18n} = useTranslation();
    const {isMobile} = useDetectGPU();
    useEffect(() => {
        if (textRef.current) {
            let newText = '';

            textRef.current.style.opacity = 1;

            text.split('').forEach((char: string) => {
                newText += '<span>' + char + '</span>';
            });

            textRef.current.innerHTML = newText;
        }
    }, []);
    const getBtnClassName = () => {
        return `sceneOneBtns-group-btn${i18n.language === 'en' ? ' en' : ''}`;
    };
    const handleText = () => {
        if (text === 'Yearly\nBrand Vision') {
            return t('YearlyBrandVision');
        }
        if (text === 'Honors\nand Awards') {
            return t('HonorsAndAwards');
        }

        return t('AboutBBIN');
    };

    const [mouseEnterAnimation, setMouseEnterAnimation] =
        useState<gsap.core.Tween>();

    useEffect(() => {
        setMouseEnterAnimation(
            gsap.fromTo(
                textRef.current.querySelectorAll('span'),
                {
                    top: '30px',
                    opacity: '0',
                    stagger: 0.03,
                    paused: true
                },
                {
                    top: '0px',
                    opacity: '1',
                    stagger: 0.03,
                    ease: 'power2.out',
                    paused: true
                }
            )
        );
    }, []);

    return (
        <button className={getBtnClassName()}>
            <div
                onClick={click}
                onMouseEnter={() => {
                    if (isMobile) return;
                    mouseEnterAnimation?.restart();
                    onHover(true);
                }}
                onMouseLeave={() => {
                    if (isMobile) return;
                    mouseEnterAnimation?.kill();
                    onHover(false);
                    gsap.to(textRef.current.querySelectorAll('span'), {
                        top: '-30px',
                        opacity: '0',
                        ease: 'power2.out'
                    });
                }}
                className="content-container">
                {/* <div className="btn-circle"></div> */}
                <div>{handleText()}</div>
            </div>

            {!isMobile &&
                createPortal(
                    <div ref={textRef} className="sceneOneBtns-text" />,
                    document.body
                )}
        </button>
    );
};

export default SceneOneBtn;
