import './App.scss';
import ProgressLoader from './components/ProgressLoader';
import {CSSTransition} from 'react-transition-group';
import {useEffect, useState} from 'react';
import FixedButtonGroup from './components/FixedButtonGroup/FixedButtonGroup';
import LoadingPage from './components/LoadingPage/LoadingPage';
import {useHlsVideo} from './hooks/useHlsVideo';
import CanvasEntrace from './components/CanvasEntrace/CanvasEntrace';
import {useGLTF, useTexture} from '@react-three/drei';
import usePreload from './features/CamMovingCenter/hook/usePreload';
import {Cache} from 'three';

const App = () => {
    const [loaderStatus, setLoaderStatus] = useState<boolean>(true);

    // canvas 場景是否開啟
    const [canvasStatus, setCanvasStatus] = useState<boolean>(false);

    // 設定影片是否開啟 true開啟影片, false 關閉影片
    const [videoStatus, setVideoStatus] = useState<boolean>(true);

    // m3u8影片，確保初始化只有一次，初始化後 傳給loading組件
    const {ref: videoRef, isVideoLoaded} = useHlsVideo();

    // 判斷是否在首頁，hallwayToLoby 有值就代表在canvas頁面
    // hallwayToLoby會放函示，執行 hallwayToLoby
    const [hallwayToLoby, setHallwayToLoby] = useState<any>(null);

    // 給 內部組件，放置長廊的位置，3個X軸的位置
    const [hallwayPositionX, setHallwayPositionX] = useState<number>(0);

    // 假計時器，模擬資源加載
    const [countNum, setCountNum] = useState<boolean>(false);
    const [currentNumber, setCurrentNumber] = useState<number | null>(null);

    useEffect(() => {
        if (canvasStatus) {
            setTimeout(() => {
                setVideoStatus(false);
            }, 2000); // 計數完畢後2秒鐘再關影片
        } else setVideoStatus(true);
    }, [canvasStatus]);

    // 趁使用者在看影片時，預先加載資源
    const {texturePreload, gltfPreload} = usePreload();
    useEffect(() => {
        // 檢查緩存資源
        const notLoadedTextures = texturePreload.filter(
            (url) => !Cache.get(url)
        );
        const notLoadedModels = gltfPreload.filter((url) => !Cache.get(url));
        // 只預加載尚未加載的資源
        if (notLoadedModels.length > 0) useGLTF.preload(notLoadedModels);
        if (notLoadedTextures.length > 0) useTexture.preload(notLoadedTextures);
    }, []);

    return (
        <div className="app" style={{height: '100%', position: 'fixed'}}>
            <CSSTransition
                in={loaderStatus}
                timeout={300}
                classNames="fade-video">
                <div className="loading-container">
                    {loaderStatus && (
                        <LoadingPage
                            setLoaderStatus={setLoaderStatus}
                            videoRef={videoRef}
                            isVideoLoaded={isVideoLoaded}
                        />
                    )}
                </div>
            </CSSTransition>

            {canvasStatus && (
                <CanvasEntrace
                    hallwayPositionX={hallwayPositionX}
                    setCanvasStatus={setCanvasStatus}
                    setHallwayToLoby={setHallwayToLoby}
                    canvasStatus={canvasStatus}
                    setVideoStatus={setVideoStatus}
                    setCountNum={setCountNum}
                />
            )}

            <CSSTransition
                in={videoStatus}
                timeout={300}
                classNames="fade-video">
                <div className="video-wrap">
                    <video
                        ref={videoRef}
                        className="cover-video"
                        muted
                        autoPlay
                        playsInline
                        loop
                        preload="auto"
                        // controls
                    />
                </div>
            </CSSTransition>

            <CSSTransition
                in={countNum && videoStatus && !loaderStatus}
                timeout={300}
                classNames="fade-loading-wrap">
                <div className="loading-wrap">
                    <div className="loading-hint">
                        <img
                            className="spin-circle"
                            src="/leading/leadingPageAssets/indexPage/dots_animation.png"
                            alt="goBetter"
                        />
                        <div className="text">
                            <ProgressLoader
                                countNum={countNum}
                                setCountNum={setCountNum}
                                currentNumber={currentNumber}
                                setCurrentNumber={setCurrentNumber}
                                setCanvasStatus={setCanvasStatus}
                            />
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <FixedButtonGroup
                videoStatus={videoStatus}
                canvasStatus={canvasStatus && !loaderStatus}
                setCanvasStatus={setCanvasStatus}
                setHallwayPositionX={setHallwayPositionX}
                hallwayToLoby={hallwayToLoby}
                setHallwayToLoby={setHallwayToLoby}
                setCountNum={setCountNum}
            />
        </div>
    );
};

export default App;
