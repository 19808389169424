import './FixedButtonGroup.scss';
import {memo, useState} from 'react';
import ContactDialog from 'components/ContactDialog/ContactDialog';
import LogoComponent from './components/LogoComponent';
import TopRightGroup from './components/TopRightGroup';
// import ContactBtn from './components/ContactBtn';
import VolumeBtn from './components/VolumeBtn';
// import { useDetectGPU, Html } from '@react-three/drei';
// import LoadingEnterBtn from 'components/LoadingPage/LoadingEnterBtn/LoadingEnterBtn';
import SceneOneBtns from 'components/SceneOneBtns/SceneOneBtns';

const FixedButtonGroup = ({
    videoStatus,
    canvasStatus,
    setCanvasStatus,
    setHallwayPositionX,
    hallwayToLoby,
    setHallwayToLoby,
    setCountNum
}: {
    videoStatus: boolean;
    canvasStatus: boolean;
    setCanvasStatus: (status: boolean) => void;
    setHallwayPositionX: (x: number) => void;
    hallwayToLoby: () => void;
    setHallwayToLoby: any;
    setCountNum: any;
}) => {
    const [contactDialog, setContactDialog] = useState(false);
    const [canCancel, setCanCancel] = useState(false);
    // const { isMobile } = useDetectGPU();

    // 添加hover狀態判斷磨砂背景
    const [isHovered, setIsHovered] = useState(false);

    return (
        // !videoStatus
        <div
            className={`btn-wrap ${
                canvasStatus && !videoStatus ? 'bottom' : 'top'
            }`}>
            <LogoComponent
                canvasStatus={canvasStatus}
                setCanvasStatus={setCanvasStatus}
                hallwayToLoby={hallwayToLoby}
                setHallwayToLoby={setHallwayToLoby}
                setIsHovered={setIsHovered}
            />
            <TopRightGroup
                canvasStatus={canvasStatus}
                setContactDialog={setContactDialog}
                setCanCancel={setCanCancel}
                hallwayToLoby={hallwayToLoby}
            />
            {/* <ContactBtn
                center={camMoveCenter.center}
                setContactDialog={setContactDialog}
                setCanCancel={setCanCancel}
            /> */}
            <VolumeBtn />
            <ContactDialog
                canCancel={canCancel}
                contactDialog={contactDialog}
                setCanCancel={setCanCancel}
                setContactDialog={setContactDialog}
            />
            {/* <LoadingEnterBtn center={camMoveCenter.center} /> */}
            <SceneOneBtns
                videoStatus={videoStatus}
                canvasStatus={canvasStatus}
                setCanvasStatus={setCanvasStatus}
                setHallwayPositionX={setHallwayPositionX}
                setCountNum={setCountNum}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
            />
        </div>
    );
};

export default memo(FixedButtonGroup);
