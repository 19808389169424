import './sceneOneBtns.scss';
import SceneOneBtn from './components/SceneOneBtn';
// import useMoveGroup from 'features/CamMovingCenter/hook/useMoveGroup';
import {useRef} from 'react';
import {useDetectGPU} from '@react-three/drei';
import SceneOneBtnMobile from './components/SceneOneBtnMobile';

const SceneOneBtns = ({
    videoStatus,
    canvasStatus,
    setCanvasStatus,
    setHallwayPositionX,
    setCountNum,
    isHovered,
    setIsHovered
}: {
    videoStatus: boolean;
    canvasStatus: boolean;
    setCanvasStatus: (status: boolean) => void;
    setHallwayPositionX: (x: number) => void;
    setCountNum: any;
    isHovered: boolean;
    setIsHovered: any;
}) => {
    // const { lobyToHallway } = useMoveGroup(center);
    // const bg = useRef<HTMLDivElement>(null);
    const sceneOneBtnsRef = useRef<HTMLDivElement>(null);
    const {isMobile} = useDetectGPU();

    // if (canvasStatus) return <></>;
    if (!videoStatus) return <></>;
    return (
        <div className="sceneOneBtns" ref={sceneOneBtnsRef}>
            {isMobile ? (
                <div className="sceneOneBtns-group">
                    <SceneOneBtnMobile
                        handleAboutBBINClick={() => {
                            setCanvasStatus(true);
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className="sceneOneBtns-group">
                        <SceneOneBtn
                            text={'About BBIN'}
                            click={() => {
                                setCountNum(true);

                                setHallwayPositionX(0);

                                // setCanvasStatus(true);
                            }}
                            onHover={setIsHovered}
                        />

                        {/* <div className="sceneOne-Btn-divider"></div> */}

                        <SceneOneBtn
                            text={'Yearly\nBrand Vision'}
                            click={() => {
                                setCountNum(true);

                                setHallwayPositionX(0.1);

                                // setCanvasStatus(true);
                            }}
                            onHover={setIsHovered}
                        />

                        {/* <div className="sceneOne-Btn-divider"></div> */}

                        <SceneOneBtn
                            text={'Honors\nand Awards'}
                            click={() => {
                                setCountNum(true);

                                setHallwayPositionX(0.5);

                                // setCanvasStatus(true);
                            }}
                            onHover={setIsHovered}
                        />
                    </div>
                    {/* 磨砂背景 */}
                    <div
                        className={`sceneOneBtns-wrap ${
                            isHovered ? 'hovered' : ''
                        }`}></div>
                </>
            )}
        </div>
    );
};

export default SceneOneBtns;
