import './loadingPage.scss';
import {memo, useEffect, useState} from 'react';
import {useDetectGPU} from '@react-three/drei';
import {Loader} from '../../utils/loader';

const LoadingPage = ({setLoaderStatus, videoRef, isVideoLoaded}: any) => {
    const {isMobile} = useDetectGPU();
    const [progress, setProgress] = useState(0);
    const [AssetsLoader] = useState<any>(new Loader());

    // 判斷app首頁資產加載，是否完成
    const [isAssetsLoaded, setIsAssetsLoaded] = useState(false);

    AssetsLoader.setOnUpdate(({progress}: any) => {
        if (progress >= 100) {
            setProgress(100);
            setIsAssetsLoaded(true);
            setProgress((prev) => Math.max(prev, 50)); // 資源加載完成佔 50%
        } else setProgress(progress / 2); // 資源加載過程佔 0-50%
    });

    useEffect(() => {
        if (AssetsLoader) {
            /* 圖片檔案產生器 > 物件 > 並自動組裝置 Loader */
            const list = ['bg.webp', 'bg_m.webp'];

            const imgData = AssetsLoader.generator(
                list,
                '/leading/leadingPageAssets/loadingPage'
            );

            /* 載入相應圖檔物件包 */
            AssetsLoader.load(imgData);
        }
    }, []);

    useEffect(() => {
        // 只有資產+影片都是true時，直接進app頁面，不等待
        if (isAssetsLoaded && isVideoLoaded) {
            setLoaderStatus(false);
        }
    }, [isAssetsLoaded, isVideoLoaded, setLoaderStatus]);

    return (
        <div
            className="loadingPage"
            style={{
                backgroundImage: `url("/leading/leadingPageAssets/loadingPage/bg${
                    isMobile ? '_m' : ''
                }.webp")`
            }}>
            <div className="loadingPage-logo">
                <img
                    src="/leading/leadingPageAssets/loadingPage/logo_animation.png"
                    alt="goBetter"
                />
            </div>
            <div className="loadingPage-progress">
                <div className="loadingPage-progress-loadingCircle">
                    <img
                        src="/leading/leadingPageAssets/loadingPage/dots_animation.png"
                        alt="goBetter"
                    />
                </div>
                <img
                    className="logo"
                    src="/leading/leadingPageAssets/loadingPage/logo_static.png"
                    alt="logo"
                />

                <p className="loadingPage-progress-percentage">
                    {`${progress.toFixed(0)}%`}
                </p>
            </div>

            <p className="hint">建议减少分页，以获得最佳浏览体验。</p>

            {/* 提早加載 */}
            <video ref={videoRef} style={{display: 'none'}} />
        </div>
    );
};

export default memo(LoadingPage);
