import {useState, useMemo, useRef, useEffect, useCallback} from 'react';
// import {useDetectGPU} from '@react-three/drei';
// import debounce from 'lodash-es/debounce';
// @ts-ignore
import Hls from 'hls.js/dist/hls.min.js'; //使用min版本, 需忽略ts檢查

export const useHlsVideo = () => {
    const ref = useRef<HTMLVideoElement>(null);
    const hlsRef = useRef<any | null>(null);
    const currentTimeRef = useRef(0);
    const [isPhoneView, setIsPhoneView] = useState(window.innerWidth < 992);
    // const {isMobile} = useDetectGPU();

    // 判斷影片是否加載完畢，給app組件使用
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    // 選擇pc或是手機影片
    const videoSrc = useMemo(
        () =>
            isPhoneView
                ? '/leading/leadingPageAssets/indexPage/phone.m3u8'
                : '/leading/leadingPageAssets/indexPage/pc.m3u8',
        [isPhoneView]
    );

    // 只有pc版本
    // const videoSrc = '/leading/leadingPageAssets/indexPage/video.m3u8';

    // hls基本設定
    const hlsConfig = useMemo(
        () => ({
            startLevel: -1, // -1自動選擇最佳品質, 0是最低品質, 1是中間品質
            autoStartLoad: false,
            capLevelToPlayerSize: false, //避免小螢幕加載高品質影片, true: 自動調整影片品質
            enableWorker: true, //啟用 Web Worker 來處理複雜的計算和解碼任務
            lowLatencyMode: false, //true:設定啟用低延遲模式,更頻繁地請求小型媒體片段, 目前不需要
            maxBufferLength: 20, // 設置為影片總長度，控制緩衝區長度
            maxMaxBufferLength: 25 // 略大於影片總長度，最大緩衝區長度
        }),
        []
    );

    const initHls = useCallback(async () => {
        const videoElement = ref.current;
        if (!videoElement) return;

        // 判斷瀏覽器是否支援hls
        if (Hls.isSupported()) {
            // 如果hlsRef有值，就先銷毀，避免拿到舊資料
            if (hlsRef.current) {
                hlsRef.current.destroy();
            }

            // 實例化hls
            const hls = new Hls(hlsConfig);

            // 將影片綁到hls對象
            hls.attachMedia(videoElement);

            // hls監聽 MEDIA_ATTACHED事件, 是否成功綁定媒體元素
            hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                // 和hls説明，要讀取的影片路徑位置
                hls.loadSource(videoSrc);
                // 開始加載
                hls.startLoad();
            });

            // 當 HLS.js 完成對 .m3u8 清單下載和解析後，它會觸發這個事件。
            hls.on(Hls.Events.MANIFEST_PARSED, (event: any, data: any) => {
                videoElement.currentTime = currentTimeRef.current;
                videoElement.play();

                // 當影片可以播放時，才關閉loading頁面
                videoElement.addEventListener(
                    'loadedmetadata',
                    () => {
                        setIsVideoLoaded(true);
                    },
                    {once: true}
                );
            });

            // 將hls對象存入hlsRef
            hlsRef.current = hls;

            // 不支援的話：使用video原生播放
        } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
            videoElement.src = videoSrc;
            videoElement.currentTime = currentTimeRef.current;
            videoElement.play();

            // 這個監聽器只會觸發一次
            videoElement.addEventListener(
                'loadedmetadata',
                () => {
                    setIsVideoLoaded(true);
                },
                {once: true}
            );

            // videoElement.play().catch((e: Error) => console.error("Error playing video:", e));
        }
    }, [videoSrc, hlsConfig]);

    useEffect(() => {
        initHls();

        return () => {
            if (hlsRef.current) {
                hlsRef.current.destroy();
            }
        };
    }, [initHls]);

    return {ref, isVideoLoaded};
};
